html {
  @apply antialiased font-sans font-normal;
  text-rendering: optimizeLegibility;
}

body {
  @apply bg-gray-lighter font-sans leading-normal text-lg text-gray-dark;
}

.wf-loading {
  @apply invisible;
}

a {
  @apply transition-colors duration-200;
}

@media (max-width: 639px) {
  .container.from-sm {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .container.from-md {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1023px) {
  .container.from-lg {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
