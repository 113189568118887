.rich-text {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  p,
  ul,
  ol {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  h2 {
    @apply font-bold text-3xl;
    margin: 2em 0 -0.25em;
  }

  h3 {
    @apply font-bold text-2xl;
  }

  h4 {
    @apply font-bold text-xl;
  }

  a {
    @apply text-blue;
    &:hover {
      @apply text-blue-darker;
    }
  }

  blockquote {
    @apply text-xl italic text-blue-dark pl-8 border-blue border-l-3 ml-8;
  }

  table,
  img,
  .wp-caption {
    margin: 2em auto;
  }

  img,
  .wp-caption {
    display: block;
    margin: 2em auto;
    max-width: 100%;
    height: auto;
  }

  .embedly-card-hug {
    @apply rounded shadow py-6 px-8 bg-white mx-auto;
  }

  .wp-caption {
    img {
      height: auto;
      margin: 0;
    }

    .wp-caption-text {
      @apply text-xs leading-normal;
      color: gray;
    }
  }

  table {
    @apply text-base leading-normal;
    text-align: left;
    border-collapse: collapse;
    margin-left: 0;
    margin-right: 0;
  }

  th,
  td {
    @apply pl-2 pr-8;
    vertical-align: top;
  }

  th {
    @apply font-display text-xs font-bold uppercase tracking-wide text-gray py-2;
  }

  td {
    @apply py-4 border-t border-gray-light;
  }
}
