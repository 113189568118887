.form-input,
.form-textarea {
  @apply font-sans text-lg rounded bg-gray-lighter py-3 px-4 block w-full border border-gray-lighter;

  &.is-invalid {
    border-color: red;
  }
}

.form-textarea {
  min-height: 12rem;
}
