.card {
  @apply block h-full bg-white rounded shadow-md max-w-sm mx-auto transition-all duration-200;

  &:hover {
    @apply shadow-lg;
  }

  &-image {
    @apply relative bg-white rounded-t;
    padding-top: 70%;

    &--tall {
      padding-top: 115%;
    }

    img {
      @apply absolute block inset-0 w-full h-full object-cover object-center rounded-t;
    }
  }

  &-title {
    @apply font-display text-gray-dark text-lg font-bold uppercase leading-tight;
  }

  &-content {
    @apply p-6 border-t-4 border-blue;
  }

  &-description {
    @apply text-base text-gray mt-2 leading-normal;
  }
}
